import React from 'react';
import { reactTrpc, ServiceDepartments } from 'utilities/react-trpc';
import { Filter, FilterItem } from '@good/ui/templates';
import { usePortalUser } from 'utilities/react-hooks/use-portal-user';
import { useTranslation } from 'react-i18next';

type ServiceDepartmentFilterProps = {
  onChange: (selectedDepartments: string[]) => void;
};

export const ServiceDepartmentFilter: React.FC<ServiceDepartmentFilterProps> = ({ onChange }) => {
  const { t } = useTranslation('', { keyPrefix: 'filters.department' });
  const portalUser = usePortalUser();

  if (!portalUser) return null;

  const { data: serviceDepartments, isLoading } = reactTrpc.serviceProvider.serviceDepartments.list.useQuery({
    serviceProviderId: portalUser.serviceProviderId,
  });

  const handleSelectServiceDepartment = (selectedDepartments: string[]) => {
    onChange(selectedDepartments);
  };

  const mapServiceDepartments = (serviceDepartments: ServiceDepartments[]): FilterItem[] =>
    serviceDepartments.map((department) => ({
      label: department.serviceDepartmentName ?? '',
      key: department.serviceDepartmentId,
    }));

  return (
    <Filter
      items={mapServiceDepartments(serviceDepartments ?? [])}
      label={t('label')}
      searchable
      clearable
      loading={isLoading}
      onChange={handleSelectServiceDepartment}
      title={t('title')}
    />
  );
};
